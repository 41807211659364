import React, { useState, useEffect } from "react"
import styles from "./index.module.scss"
import Menubar from "../components/menubar/menubar"
import Sidebar from "../components/sidebar/sidebar"
import SidebarExt from "../components/sidebar/sidebarExt"
import ProductFoursome from "../components/productFoursome/productFoursome"
import Footer from "../components/footer/footer"
import FormComp from "../components/formComp/formComp"
import Year40 from "../img/40yil.svg"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import car_1 from "../img/carou1.jpg"
import car_2 from "../img/carou2.jpg"
import car_3 from "../img/carou3.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"

const Index = () => {
  const [wSize, setWSize] = useState("default")
  var myFunc = function() {
    var y = window.innerWidth
    if (y <= 577) {
      setWSize("none")
    } else {
      setWSize("default")
    }
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      myFunc()
    }
  })
  const data = useStaticQuery(graphql`
    query {
      project1: file(relativePath: { eq: "nyKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project2: file(relativePath: { eq: "kirklareliKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project3: file(relativePath: { eq: "onVillaKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project4: file(relativePath: { eq: "syKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.index}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Sidebar />
        <SidebarExt />
        <Carousel
          id="mainpageCar"
          className={styles.mainCarWindow}
          nextIcon={
            <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" alt="ileri" />
          }
          prevIcon={
            <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" alt="geri" />
          }
          controls={true}
          indicators={false}
          fade={true}
          interval="3000"
        >
          <Carousel.Item>
            <img src={car_1} alt="car1" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                ÇİZGİDEN <br />
                <span>YAŞAM ALANINA</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                ATİA mekânı doğru biçimde kullanan, karakterli ve ergonomik
                yaşam alanlarında bulunmak isteyen herkesin, yaşam kalitesini ve
                konforunu öne çıkartan mekanlar sunar.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={car_2} alt="car2" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                KALİTEDEN <br />
                <span>DETAYA</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                Projelerinde uygulama kalitesiyle ve detaylara getirdiği
                yenilikçi ve farklı çözümlemeleriyle öne çıkan ATİA AHŞAP,
                bünyesinde barındırdığı iç mimarlar ve teknik ekiple iç
                mimarlık, proje, uygulama ve danışmanlık hizmeti de vermektedir
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={car_3} alt="car3" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                DENEYİM VE <br />
                <span>BİRİKİM</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                Arkasında 36 yıllık bir deneyim ve birikim bulunduran ATİA
                markası, üretime işin temelinden başlayıp, bugünkü kalite
                çizgisine ulaşmıştır
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Menubar />
        <ProductFoursome
          className={styles.productsContainerIndex}
          displayProp={wSize}
          /* Product 1-1 */
          iSrc1={data.project1.childImageSharp.fluid.src}
          info1={`NY \nKONUTU`}
          iLink1={`/projeler/nyKonutuPD`}
          /* Product 1-2 */
          iSrc2={data.project2.childImageSharp.fluid.src}
          info2={`KIRKLARELİ VALİLİĞİ \nTOPLANTI SALONU`}
          iLink2={`/projeler/kirklareliValilik`}
          /* Product 1-3 */
          iSrc3={data.project3.childImageSharp.fluid.src}
          info3={`DATÇA - BURGAZ \nVİLLALARI`}
          iLink3={`/projeler/datcaBurgazVilla`}
          /* Product 1-4 */
          iSrc4={data.project4.childImageSharp.fluid.src}
          info4={`SY \nKONUTU`}
          iLink4={`/projeler/syKonutu`}
        />
        <div className={styles.year36Container}>
          <img src={Year40} alt="40 year" />
        </div>
        <FormComp displayValue={wSize} />
        <Footer displayVal={wSize} />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default Index

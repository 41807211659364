import React from "react"
import styles from "./sidebar.module.scss"
import LogoStand from "../../img/logo-standalone.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"

export default props => (
  <div className={styles.sidebar}>
    <img src={LogoStand} alt="logo-standalone" className={styles.logoStand} />
    <div className={styles.verticalLineWhite}></div>
    <div className={styles.socialMediaIcons}>
      <a
        href="https://www.facebook.com/atiaahsap"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          size="1x"
          className={styles.ffIcon}
        />
      </a>
      <br />
      <a
        href="https://www.instagram.com/atiaahsap/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          size="1x"
          className={styles.igIcon}
        />
      </a>
    </div>
  </div>
)

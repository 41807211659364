import React from "react"
import styles from "./formComp.module.scss"
import { Form, Col, Button } from "react-bootstrap"

export default props => (
  <div
    className={styles.formCompContainer}
    style={{ display: `${props.displayValue}` }}
  >
    <div className={styles.formTitle}>
      İLETİŞİMDE <span style={{ color: "#977851" }}>KALALIM</span>
      <span className={styles.backContactText}>iletişim</span>
    </div>
    <div className={styles.fe1}></div>
    <div className={styles.fe2}></div>
    <div className={styles.formArea}>
      <div className={styles.textFormleft}>
        <span>90 312 348 6307</span>
        <br />
        <span>atia@atia.com.tr</span>
        <br />
        <span>Ereğli Cad. No: 65/3 - 4</span>
        <br />
        <span>Siteler/ANKARA</span>
      </div>
      <div className={styles.formForm}>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control placeholder="İsim" className={styles.fTextArea} />
              <Form.Control
                placeholder="E-Mail *"
                className={styles.fTextArea}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                placeholder="Telefon"
                className={styles.fTextArea}
              />
              <Form.Control placeholder="Şirket" className={styles.fTextArea} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                placeholder="Mesaj"
                as="textarea"
                rows="5"
                className={styles.fTextAreaBig}
              />
            </Form.Group>
          </Form.Row>
          <Button type="submit" className={styles.formButton}>
            Gönder
          </Button>
        </Form>
      </div>
    </div>
  </div>
)
